//
// Home header Grid-Layout
//

.grid-5-5{
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(5, 1fr); 
    grid-template-rows: repeat(5, 1fr); 
    background-color:$color-blue-light;
    .gitem{
        border:1px solid #0088E4;
        color: $color-white;
        position:relative;
        p.cta{
            text-align:right;
            a:after{
                content: "\e071\e071";
                display: inline-block;
                font-family: "simple-line-icons";
                font-size: 0.5rem;
                vertical-align: middle;
                position: relative;
                top: -1px;
                margin-left: 5px;
            }
        }
    }
    a{
        color:$color-white;
        text-decoration:underline;
    }
    h1, h2, h3, .h1, .h2, .h3{
        color: $color-white;
    }
    h1, .h1{
        font-size: 2.5rem;
        font-weight: bold;
    }
    h2, .h2{
        font-size: 1.1rem;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    h3, .h3{
        font-size: 1.25rem;
    }
    p{
        font-size: 0.75rem;
    }
}
.g-image-wrapper{
    position:absolute;
    top:0.5rem;
    bottom:0.5rem;
    left:0.5rem;
    right:0.5rem;
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    &:hover, &:active, &:focus{
        text-decoration:none;
    }
    h3{
        font-family: 'Righteous', cursive;
        font-size: 1.5rem;
        font-weight:300;
        text-align:center;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 40%,rgba(0,0,0,1) 100%);
        margin: 0;
        padding: 2rem 0 0.5rem;
        .sub{
            display:block;
            font-size: 1.25rem;
        }
    }
}

.gi-01{
    grid-column: 1 / span 1;
    grid-row: 1 / span 3;
    .g-image-wrapper{
        background-position-y: center;
        background-position-x: right;
    }
}
.gi-02{
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}
.gi-03{
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
}
.gi-04{
    grid-column: 3 / span 2;
    grid-row: 1 / span 2;
    .pdw-image{
        width:49%;
        float:left;
    }
    .pdw-title{
        width:49%;
        float:right;
        text-align: center;
    }
    .pdw-text{
        width:49%;
        float:right;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        h2{
            text-align: center;
            font-size:1.5rem;
        }
        a{
            text-decoration: none;
            &:hover{
                p{
                    color:#fff;
                }
            }
        }
        p.cta a{
            text-decoration: underline;
        }
        
    }
}
.gi-05{
    grid-column: 5 / span 1;
    grid-row: 1 / span 2;
}
.gi-06{
    grid-column: 2 / span 2;
    grid-row: 3 / span 1;
}
.gi-07{
    grid-column: 4 / span 1;
    grid-row: 3 / span 1;
}
.gi-08{
    grid-column: 5 / span 1;
    grid-row: 3 / span 3;
}
.gi-09{
    grid-column: 1 / span 1;
    grid-row: 4 / span 2;
    .g-image-wrapper{
        background-position-y: bottom;
        background-position-x: center;
    }
}
.gi-10{
    grid-column: 2 / span 1;
    grid-row: 4 / span 2;
}
.gi-11{
    grid-column: 3 / span 1;
    grid-row: 4 / span 2;
}
.gi-12{
    grid-column: 4 / span 1;
    grid-row: 4 / span 2;
}
@media only screen and (max-width: 1199px){
    .grid-5-5{
        grid-template-columns: repeat(4, 1fr); 
        grid-template-rows: repeat(7, 1fr); 
        .gi-01{
            grid-column: 1 / span 1;
            grid-row: 1 / span 3;
        }
        .gi-02{
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
        }
        .gi-03{
            grid-column: 2 / span 1;
            grid-row: 2 / span 2;
        }
        .gi-04{
            grid-column: 3 / span 2;
            grid-row: 1 / span 2;
        }
        .gi-05{
            grid-column: 4 / span 1;
            grid-row: 6 / span 2;
        }
        .gi-06{
            grid-column: 1 / span 2;
            grid-row: 4 / span 1;
        }
        .gi-07{
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;
        }
        .gi-08{
            grid-column: 4 / span 1;
            grid-row: 3 / span 3;
        }
        .gi-09{
            grid-column: 3 / span 1;
            grid-row: 5 / span 3;
        }
        .gi-10{
            grid-column: 3 / span 1;
            grid-row: 3 / span 2;
        }
        .gi-11{
            grid-column: 1 / span 1;
            grid-row: 5 / span 2;
        }
        .gi-12{
            grid-column: 2 / span 1;
            grid-row: 5 / span 3;
        }
    }
}
@media only screen and (max-width: 991px){
    .grid-5-5{
        grid-template-columns: repeat(3, 1fr); 
        grid-template-rows: repeat(9, 1fr); 
        .gi-01{
            grid-column: 1 / span 1;
            grid-row: 4 / span 3;
        }
        .gi-02{
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }
        .gi-03{
            grid-column: 2 / span 1;
            grid-row: 8 / span 2;
        }
        .gi-04{
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
        }
        .gi-05{
            grid-column: 1 / span 1;
            grid-row: 2 / span 2;
        }
        .gi-06{
            grid-column: 2 / span 2;
            grid-row: 5 / span 1;
        }
        .gi-07{
            grid-column: 3 / span 1;
            grid-row: 6 / span 1;
        }
        .gi-08{
            grid-column: 3 / span 1;
            grid-row: 7 / span 3;
        }
        .gi-09{
            grid-column: 2 / span 1;
            grid-row: 3 / span 2;
        }
        .gi-10{
            grid-column: 1 / span 1;
            grid-row: 7 / span 3;
        }
        .gi-11{
            grid-column: 2 / span 1;
            grid-row: 6 / span 2;
        }
        .gi-12{
            grid-column: 3 / span 1;
            grid-row: 3 / span 2;
        }
    }
}
@media only screen and (max-width: 767px){
    .grid-5-5{
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(13, 1fr); 
        p{
            font-size: 1rem;
            a{
                line-height: 2rem;
            }
        }
        .gi-02{
            grid-column: 1 / span 2;
            grid-row: 1 / span 1;
        }
        .gi-06{
            grid-column: 1 / span 2;
            grid-row: 2 / span 1;
        }
        .gi-09{
            grid-column: 1 / span 1;
            grid-row: 3 / span 2;
        }
        .gi-12{
            grid-column: 2 / span 1;
            grid-row: 3 / span 2;
        }
        .gi-04{
            grid-column: 1 / span 2;
            grid-row: 5 / span 2;
        }
        .gi-05{
            grid-column: 1 / span 1;
            grid-row: 7 / span 2;
        }
        .gi-08{
            grid-column: 2 / span 1;
            grid-row: 7 / span 3;
        }
        .gi-11{
            grid-column: 1 / span 1;
            grid-row: 9 / span 2;
        }
        .gi-10{
            grid-column: 2 / span 1;
            grid-row: 10 / span 2;
        }
        .gi-03{
            grid-column: 1 / span 1;
            grid-row: 11 / span 1;
        }
        .gi-07{
            grid-column: 1 / span 1;
            grid-row: 12 / span 1;
        }
        .gi-01{
            grid-column: 2 / span 1;
            grid-row: 12 / span 3;
        }
    }
}
@media only screen and (max-width: 479px){
    .grid-5-5{
        display:block;
        .gitem{
            clear:both;
        }
        .gi-02{
        
        }
        .gi-06{
        }
        .gi-09{
            height:90vw;
        }
        .gi-12{
        }
        .gi-04{
            .pdw-image{
                width:auto;
                float:none;
                text-align:center;
            }
            .pdw-title{
                width:auto;
                float:none;
                display:block;
            }
            .pdw-text{
                margin-top: 1rem;
                width:auto;
                float:none;
                display:block;
            }
        }
        .gi-05{
        }
        .gi-11{
                height:90vw;
        }
        .gi-10{
        }
        .gi-08{
                height:130vw;
        }
        .gi-07{
        }
        .gi-01{
                height:130vw;
        }
        .gi-03{
        }
    }
}