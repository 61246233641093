//
// General Styles
//

body a.ugiftcert-remove {
    color: $color-grey-dark;
}

#tsbadge4_db8d3657bdbe440c985ae127463eaad4 {
  left: 0px;
  width: 90px;
}

.vertical-align {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

/*.add-to-cart>div {
   display: inline-block;
}*/
.product-view .add-to-cart-flex{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: end;
    .quantity-wrapper{
        margin-bottom: 0.125rem;
    }
    &.add-to-cart-flex > *{
        margin:0 0.25rem 0.125rem;
    }
}

/*.middle-block {
  background: url("../images/bar_eaters-collective-min.jpg");
  background-size: cover;
  background-position: center center;
}*/

//
//
// Home
//
.slider_wrapper{
    height: 125px;
    .owl-carousel {
        .owl-stage {
            display: flex;
            flex-direction: row;
            align-content: stretch;
            .slider-item{
                height: 100%;
                display: flex;
                justify-content: center;
                img {
                    max-height: 100px;
                    margin:auto;
                    vertical-align: middle;
                    width: auto;
                }
            }
        }
    }
}
.owl-carousel {
    .owl-dots {
        display:none;
        & + .owl-nav {
            margin-top: -3rem;
        }
        text-align: center;
        line-height:3rem;
        .owl-dot {
            border: 5px solid $color-grey-light;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin:0 2px;
            border-radius: 5px;
            &.active{
                border-color:$color-grey-dark;
            }
        }
    }
    .owl-nav{
        .owl-prev, .owl-next{
            font-size: 3rem;
            line-height: 3rem;
            transition: color 0.5s ease;
            &:hover{
                color:$color-grey-light;
            }
        }
        .owl-prev{
            float: left;
        }
        .owl-next{
            float: right;
        }
    }
}

.section{
    .expandable {
        .content{
            overflow:hidden;
            transition: max-height 0.5s ease;
            &.minimized{
                max-height: 300px;
            }
        }
        .toggletext{
            &:before{
                content: 'weniger';
            }
            &.maxify:before{
                content: 'Weiter lesen...';
            }
        }
    }
}

section{
    .widget-background {
        //background: rgba(0, 48, 115, 0.75);
        background: rgba(36, 113, 160, 0.75);
        padding: 1em;
        overflow: hidden;
        color: $color-white;
        h2, h3, h4 {
          color: $color-white;
        }
        .content{
            overflow:hidden;
            transition: max-height 0.5s ease;
        }
        .toggletext{
            &:before{
                content: 'weniger';
            }
            &.maxify:before{
                content: 'Weiter lesen...';
            }
        }
    }

    &.widget-title {
        background-size: cover;
        background-position-y: top;
        background-position-x: center;
        background-repeat: no-repeat;
          .row {
            padding: 50px 65px;
            .widget-background {
                a {
                  color: $color-orange;
                }
            }
        }
    }
    &.widget-title-scotland {
      background-image: url("../images/scotland_george-hiles-min.jpg");
    }

    &.widget-title-irland {
      background-image: url("../images/irland_wynand-van-poortvliet-min.jpg");
    }

    &.widget-title-new {
      background-image: url("../images/whiskylager_min.jpg");
    }

    &.widget-title-glass {
      background-image: url("../images/glencairn_henry-fournier-min.jpg");
    }

    &.widget-title-bar {
      background-image: url("../images/BG_best_whisky.jpg");
    }

    &.widget-title-award {
      // background-image: url("../images/awards-min.jpg");
      background-image: url("../images/BG_best_whisky.jpg");
    }

    &.widget-title-spirits {
      background-image: url("../images/bar_eaters-collective-min.jpg");
    }
    .transparent2white{
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.9) 90%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.9) 90%, rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.9) 90%, rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    .products-grid{
        display:flex;
        flex-wrap:wrap;
        justify-content: flex-start;
        .item{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: column;
            .product-shop{
            }
        }
        &:before{
            content:''!important;
            display:none!important;
        }
    }
}
.products-grid{
    display:flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    .item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        .product-shop{
        }
        .image-box{
            flex-grow:0;
        }
        .product-details{
            flex-grow:1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
        }
    }
}

.box-up-sell {
    padding: 0 0 24px 0;
    .products-grid{
        .upsell-product{
            text-align: center;
            .product-name{
                a{
                    font-weight:300;
                }
            }
            .price-box{
                margin: auto;
                .regular-price, .minimal-price-link{
                    display:block;
                    margin:0 auto 5px;
                    padding:0;
                }
            }
        }
    }
}
.bg-white{
    background-color: $color-white!important;
}

.cart-block{
    .discount{
        .buttons-set{
            margin-top: 0;
            text-align:center;
            .btn{
                margin-top: 1rem;
            }
        }
    }
}

.row{
    &.justify-content-between,
    &.justify-content-around,
    &.row.align-items-center,
    &.flex{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.flex{
    display: -webkit-flex;
    display: flex;
    &.wrap{
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}


.justify-content-between {
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -webkit-justify-content: space-around !important;
    justify-content: space-around !important;
}

.align-items-center {
    -webkit-align-items: center !important;
    align-items: center !important;
}
.product-view {
    .product-shop {
        .product-shop-inner{
            .tier-prices{
                margin-bottom:1rem;
                .tier-price{
                  .price-qty{
                    font-weight:700;
                    font-size: 1rem;
                  }  
                }
            }
        }
    }
}
.cart{
    #shopping-cart-table{
        .update_cart_button{
            margin-top:0.5rem;
        }
        .item-options {
            dd {
                & + dt{
                    border: none;
                    padding-left: initial;
                }
            margin: 0 5px 0 0;
            }
        }
    }
}
.ts-wrapper{
    text-align:center;
    padding: 2rem 0;
}
@media only screen and (min-width: 480px) {
    .owl-carousel {
        .owl-dots {
            display:block;
        }
    }
    section{
        .products-grid.five-columns{
            .item{
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    section{
        .products-grid.five-columns{
            .item{
                width:33%!important;
            }
        }
        .widget-background {
            .content{        
                column-count: 2;
                column-gap: 2em;
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    section{
        .products-grid.five-columns{
            .item{
            }
        }
        .widget-background {
            .content{        
                column-count: 3;
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    section{
        &.widget-title {
            .row {
                padding: 50px 65px;
            }
            .products-grid.five-columns{
                .item{
                    width:20%!important;
                }
            }
        }
    }
}
@media only screen and (max-width: 1199px) {
    section{
        &.widget-title {
            .row {
                padding: 2vw 5vw;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    section{
        .widget-background {
            .content {
                &.minimized {
                    max-height:150px;
                }
            }
            .content{        
                column-count: 1;
                column-gap: 2em;
            }
        }
    }
    .box-up-sell {
        .products-grid{
            display:initial;
            .upsell-product{
                .price-box{
                    margin: auto;
                }
            }
        }
    }
    .product-view .tab-content{
        padding: 0.5rem;
    }
}

#collapseBtnWasser {
  margin-bottom: 14px;
}
#collapseBtnWasser[aria-expanded=true] {
  display: none;
}

.availability.out-of-stock {
  background-color: $color-grey-light;
  color: $color-white;
  padding: 0.5rem 1rem;
}

.btn-cart, .btn-primary.btn-proceed-checkout, body .btn-primary.type-2, .btn-primary.btn-checkout {
    background-color: $color-orange-light;
    color: $color-grey-dark;
    border-width: 1px;
    border-style: solid;
    border-color: $color-orange-dark;
    &:hover, &:focus, &:active {
        background-color: $color-blue-light;
        color: $color-white;
        border-color: $color-blue-dark;
    }
}

.products-grid .btn.btn-cart, .products-list .btn.btn-cart {
    background-color: $color-orange-light;
    color: $color-grey-dark;
    border-width: 1px;
    border-style: solid;
    border-color: $color-orange-dark;
    &:hover, &:focus, &:active {
        background-color: $color-blue-light;
        color: $color-white;
        border-color: $color-blue-dark;
    }
}

.image-box .hover-buttons {
  display: flex;
  .add-to-links {
    margin: 0;
  }
}

.price-box {
    display: table;
    text-align:center;
    .regular-price {
        display: inline;
    }
    .minimal-price-link {
        display: block;
        padding-left: unset;
        margin: 1rem 2rem;
    }
}

.price {
  color: #000;
}

.product-view .top-wrapper .price-box .price {
  color: #000;
}

#checkout-review-table, .checkout-review-table {
    .price{
        font-size: 14px;
        font-weight:300;
    }
    strong{
        font-weight:700;
        .price{
            font-weight:700;
        }
    }
}
.category-products {
    .minprice{
        color: #43a047;
        font-weight: bold;
        font-size: 0.75rem;
    }
}
.bankpayment_info, .bankpayment_info_int{
    .bankcol1, .bankcol2{
        font-size: 0.75rem;
    }
}
.success.bankpayment_info{
    td {
        padding: 2px 20px 2px 0;
    }
    .bankcol1{
        font-size:1.5rem;
        font-weight:normal;
        color:$color-grey;
    }
    .bankcol2{
        font-size:1.5rem;
        font-weight:normal;
        color:$color-grey-dark;
    }
}

body .btn {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    text-transform: none;
    padding: 10px 22px;
    &.btn-white-danger{
        background-color: $color-white;
        border: 1px solid $color-grey-light;
        color: $color-grey-dark;
        &:hover, &:active, &:focus{
            background-color: $color-red;
            border: 1px solid $color-red;
            color: $color-white;
        }
    } 
    &.btn-white-default{
        background-color: $color-white;
        border: 1px solid $color-grey-light;
        color: $color-grey-dark;
        &:hover, &:active, &:focus{
            background-color: $color-blue-light;
            border: 1px solid $color-blue-dark;
            color: $color-white;
        }
    } 
}
//
// Header
//

@media only screen and (min-width: 768px) {
  .header .middle-block {
    .left-align {
      text-align: left;
    }
    .right-align {
      text-align: right;
    }
  }
    .products-list .product-name {
      text-align:left;
    }
    .products-list .ratings {
      justify-content: left;
    }
    body .products-list .price-add-to-wrapper{
        text-align: left;
    }
    body .products-list .availability-box{
        text-align: left;
    }
}
@media only screen and (max-width: 767px) {
    body .products-list .product-buttons-wrapper{
        text-align:center;
    }
    body .products-list .btn{
        float:none!important;
    }
}

//
// Header
// Search
//

.header .links {
  padding-left: 0;
}

.header .search-mini-form {
  float: none;
  max-width: 240px;
}

.header .search-mini-form .form-search .input-wrapper {
  background-color: $color-blue-light;
}

.header {
    .navbar {
        .navbar-header {
            .navbar-toggle {
                margin-right:2px;
            }
        }
    }
    .mobile-navigation {
         margin-left: 47px;
        .cart-mobile, .customer-mobile{
             font-size:24px;
             padding-top:4px;
             padding-right:10px;
             a {
                 color:$color-white;
                 &:hover, &:active, &:focus {
                     color:$color-orange;
                 }
             }
         }
        .customer-mobile{
            padding-left:12px;
        }
        .search-mobile{
            margin-right: 72px;
            .search-mini-form {
                width: auto;
                margin: 5px auto;
                display: block;
                .form-search {
                    .input-wrapper {
                        background-color: $color-white;
                        input{
                            padding-right:0;
                            color:$color-grey-dark;
                        }
                    }
                    .btn{
                        border-radius:0;
                        background-color: $color-white;
                        color: $color-blue-light;
                    }
                }
            }
        }
    }
}
.my-account{
    .dashboard{
        .signout{
            float: right;
            padding: 0 12px;
            a{
                font-size: 24px;
                line-height: 1.5em;
                color: $color-grey-dark;
                 &:hover, &:active, &:focus {
                     color:$color-orange;
                 }
            }
        }
    }
}
//
// Header
// Top Cart
//

#header ul.topmenu li.level0 a.level-top {
  font-weight: bold;
}

.nav-wide .menu-wrapper ul.level0 li.level1 span.subtitle {
  text-transform: uppercase;
}

header.header {
    .top-cart {
        margin-top: 5px;
        margin-bottom: 5px;
        .block-title {
            .cart-label {
                padding-right: 5px;
            }
            .top-cart-icon{
                i{
                    &.fa-count {
                        font-family: Montserrat,sans-serif;
                    }
                }
            }
        }
        h4, h5, .h4, .h5{
            font-weight:300;
        }
    .block-content {
        .mini-products-list {
            .product-name{
                    a{
                        font-weight:300;
                    }
                }
                .product-box{
                    .price{
                        font-weight:300;
                    }
                }
            }
            .subtotal {
                display:block;
                text-align:right;
                .price {
                    font-weight:300;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
  .free-shipping-limit.placeholder {
    min-height: 21px;
  }
}

//
// Header
// Main Menu
//

.header .bottom-block .main-menu-wrapper {
  border-radius: 0;
}

#header ul.topmenu li.level0.first a.level-top {
  border-radius: 0;
}


//
// Sidebar
// Cart Free Shipping Limit
//

.block-cart .block-content .summary .subtotal .price {
  font-weight: bold;
}

// Breadcrumbs

.breadcrumbs-wrapper .page-title + .breadcrumb {
     margin: 15px 0 10px;
}
.breadcrumbs-wrapper .breadcrumbs-inner {
  padding-bottom: 0;
}

//
// Catalog Category Description Collapse
//
.category-cms-block {
    column-count:3;
    .category-image-wrapper{
        text-align:center;
        .category-image{
            width:auto;
        }
    }
}

.btn-toggle {
  display: none;
}

@media only screen and (max-width: 767px) {
.category-cms-block {
    column-count:1;
}
  .category-description {
    margin-bottom: 0.5rem;
   &.minimized {
     height: 118px;
     overflow: hidden;
   }
 }
  .btn-toggle {
    display: block;
  }
}

//
// Toolbar Pager
//

@media only screen and (max-width: 767px) {
  .toolbar .pager {
    z-index: 3;
  }
}

//
// Product View
//

.product-view .short-description {
  margin-top: 0.5rem;
}

.product-view .std {
  line-height: 1.5em;
}

//
// Product View
// Notes
//

.product-view .tasting-notes h2 {
//  font-size: 20px;
//  line-height: 28px;
//  margin-bottom: 10px;
}

.product-view .tasting-notes h3 {
//  font-size: 16px;
//  line-height: 24px;
//  margin-bottom: 4px;
}



//
// Product View
// Tabs
//

.video-responsive {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  position: relative;
  padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

#tabs .tab-content .tab-pane {
  margin-left: -30px;
  margin-right: -30px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;

  margin-top: -105px;
  padding-top: 120px;
}

@media only screen and (min-width: 768px) {
  #tabs .tab-content .tab-pane {
    margin-top: -120px;
  }
  .col-no-space-l {
    padding-left: 0;
  }
  .col-no-space-r {
    padding-right: 0;
  }
  .col-no-space-b {
    padding-bottom: 0;
  }
  .col-no-space-t {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-2 {
    column-count: 2;
  }
}

#product-attribute-specs-table th {
  background-color: transparent;
}

//
// Product View Reviews
//

.box-reviews .customer-reviews-wrapper {
  .customer-icon {
    height: 75px;
    width: 75px;
    border: 5px solid $color-grey-light;
    border-radius: 50% 0 50% 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    i {
      font-size: 24px;
      color: $color-grey-light;
      line-height: 65px
    }
  }
  .customer-date {
    display: flex;
    font-weight: bold;
  }
}
@media only screen and (min-width: 768px) {
    .box-reviews .customer-reviews-wrapper {
        .review-content-box{
            &>div{
                margin-left:-15px;
                margin-right:-15px;
            }
        }
    }
}
//
// Product View
// Review Form
//

#review-form .rating-inputs .form-list .input-box {
  input {
    color: $color-grey;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid $color-grey-light;
  }
  textarea {
    color: $color-grey;
    font-size: 14px;
    height: 60px;
    border: 0;
    border-bottom: 1px solid $color-grey-light;
    ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
      color: $color-grey-light;
    }
  }
}

.box-reviews .rating-block .stars-wrapper .value i.active {
  color: $color-orange;
}

//
// Prduct View Label New
//

.product-labels-wrapper>div.label-new {
  height: 75px;
  width: 75px;
  font-size: 25px;
  font-weight: bold;
  padding-top: 22px;
  background-color: $color-green;
  border: 3px solid white;
  box-shadow: 0 0 0 3px $color-green;
  border-radius: 50%;
}

//
// Prduct View Label Sale
//

.product-labels-wrapper>div:not(.label-new) {
  height: 75px;
  width: 75px;
  font-size: 25px;
  font-weight: bold;
  padding-top: 22px;
  background-color: $color-red;
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0 0 0 3px $color-red;
}

//
// Prduct View Label Sale Timer
//

.timer-box {
  border-bottom: solid 1px rgba(0,0,0,.1);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  .label-sale-timer {
    height: 60px;
    width: 200px;
    background-color: $color-red;
    border: 3px solid white;
    border-radius: 30px;
    box-shadow: 0 0 0 3px $color-red;
    text-align: center;
    color: #fff;
    font-family: Montserrat, sans-serif;
    .title {
      line-height: 20px;
      margin-top: 3px;
    }
    .days-row, .hours-row {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      .days {
        font-weight: normal;
        >span {
          font-weight: bold;
        }
      }
      .col-xs-12 {
        display: inline;
      }
    }
  }
}

//
// Catalog Product Grid Timer
//

.products-grid .timer-box {
  border-bottom: none;
  padding-bottom: 0;
  .label-sale-timer {
    height: 48px;
    border-radius: 24px;
    .title {
      line-height: 16px;
    }
    .days-row, .hours-row {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

//
// Catalog Product Grid Timer
//

.products-list .timer-box {
  .label-sale-timer {
    height: 48px;
    border-radius: 24px;
    .title {
      line-height: 16px;
    }
    .days-row, .hours-row {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

//
// Footer
//

#footer p{
    color: $color-white;
}

ul.payment-shipping-icons {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    li {
        padding: 5px 10px;
    }
}

//
// Cart
//

.checkout-types li{
    margin-bottom: 0.5rem;
}

.btn-qty i:hover {
    color: $color-white;
}

//
// Checkout
//
.opc #opc-shipping_method .shipping_description
{
    padding:10px;
}


//
// Amazonpayments Advanced Checkout
//

.apa {
  #apa-shipping {
    width: 50%;
    margin-right: 1%;
  }
  #apa-payment {
    width: 49%;
    margin-right: 0;
  }
  #apa-shipping_method {
    width: 100%;
    margin-left: 0;
    margin-bottom: 12px;
    height: auto;
  }
}

#checkoutSteps .btn-default {
    background-color: $color-orange-light;
    color: #333;
    border-width: 1px;
    border-style: solid;
    border-color: $color-orange-dark;
    &:hover {
        background-color: $color-blue-light;
        color: $color-white;
        border-color: $color-blue-dark;
    }
}
#checkout-review-table, #details-table, .shopping-cart-totals-table {
    .summary-details{
        td{
            background-color:#fafafa;
        }
        .last{
            .price{
                font-size:11px;
            }
        }
    }
    .tax-name{
        
    }
    .summary-collapse {
        .icondown{
            display:inline;
        }
        .iconup{
            display:none;
        }
    }
    .show-details{
        .summary-collapse {
            .icondown{
                display:none;
            }
            .iconup{
                display:inline;
            }
        }
    }
}
.shopping-cart-totals-table {
    .tax-name, .tax-amount{
        border: none
    }
}
/// Gewinnspiel Glenfiddich

.background-glenfiddich {
  background-image: url(../images/cms/CMS-Email-BG@1x.jpg);
}

.background-glenfiddich-after {
  background-color: #ebebeb;
}

/// Gewinnspiel Glenfiddich Kacheln

.mr--13 {
  margin-right: -13px;
}

/// Checkout Baseprice Info

#checkout-review-table {
  .baseprice-info {
    font-size: 12px;
    .price {
      font-size: 12px;
    }
  }
}

// CMS Page Styling

.with-breadcrumbs + .content-wrapper .page-title {
    display: block;
    border-bottom: none;
    padding-bottom: 0;
}

@media only screen and (min-width: 768px) {
    .cms-page {
        p {
            //column-count: 2;
        }
    }
}
@media only screen and (min-width: 992px) {
    .cms-page-agb {
        h3 {
            margin-top: -75px;
            padding-top: 75px;
        }
        h4 {
            margin-top: -75px;
            padding-top: 75px;
        }
    }
}

.cms-page-shipping {
    span strong {
        color: $color-orange-dark;
    }
}