h1,
body .h1, body h1,
.page-title h1,
.breadcrumbs-wrapper .page-title h1{
    font-size: 36px;
    font-weight: 300;
    line-height: 40px;
}

h2,
body .h2, body h2,
.page-title h2,
.breadcrumbs-wrapper .page-title h2{
    font-size: 30px;
    font-weight: 300;
    line-height: 38px;
}

h3,
body .h3, body h3,
.page-title h3,
.breadcrumbs-wrapper .page-title h3{
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.bold{
  font-weight: 700;
}

.baseprice-box {
  font-size: 12px;
  color: $color-grey-dark;
  .price {
    font-size: inherit;
    color: inherit;
    font-weight: 300;
  }
}

.tax-box {
  font-size: 12px;
  color: $color-grey-dark;
  a {
    color: $color-grey-dark !important;
    text-decoration: underline;
  }
}
@import url('/skin/frontend/porter/sws/fonts/Righteous.css');