.sidebar .paypal-logo {
  margin: .75em 0 .75em;
}

.btn{
  &.btn-paypal {
    background: #FFC837;
    border: 1px solid #DE941E;
    padding: 9px 22px;
    img {
      height: 19px;
    }
  }
  &.btn-amazon {
    background: #FFC837;
    border: 1px solid #DE941E;
    padding: 9px 22px;
    img.amazonPayButtonCustom {
      height: 19px;
      top:     4px;
    }
  }
}

.block{
  &.totals{
    .checkout-types{
      .btn{
        width: 80%;
      }
    }
  }
}

.payButtonWidget{
    position:relative;
    .amazon-payment-button-loaded{
        left: 0;
        top: 0;
        position: absolute;
        opacity: 0;
        width:100%;
        height:100%;
    }
}

//.checkout-types>li>.payButtonWidget>img {
//  width: 145px;
// }

.product-shop .add-to-box .payButtonWidget>img {
  width: 145px;
}

.checkout-agreements{
  list-style: none;
  padding-left: 0;

  input{
    display: inline-block;
  }

  label{

  }
}

#checkout-review-table{
  width: 99.9%;
  td{
    &.last{
      text-align: right;
    }
  }
}

.checkout-agreements .agree {
  padding: 0 6px;
}