/* Footer ================================================================================ */
#footer {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  .contacts-footer-content {
    input.form-control,
    textarea.form-control,
    select.form-control {
      background-color: transparent;
      border-width: 0 0 1px 0;
      border-bottom: 1px dashed #444;
      color: #aaa;
      font-size: 11px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 8px;
    }

    .input-box span:before {
      content: "";
      display: block;
      position: absolute;
      border-top: dashed 1px;
      border-bottom: dashed 1px;
      border-color: #444;
      height: 29px;
      width: 100%;
      top: 19px;
    }

    .input-box span:after {
      content: "";
      display: block;
      position: absolute;
      border-top: dashed 1px;
      border-color: #444;
      height: 28px;
      width: 100%;
      top: 74px;
    }
  }

  .footer-middle {
    font-size: 14px;
  }
}

body.wide-footer #footer,
body.boxed-footer #footer .container {
  background-color: $color-white;
}

#footer .contacts-footer-content textarea.form-control {
  border: none;
  height: 80px;
  line-height: 27px;
  padding-top: 0;
  padding-bottom: 0;
}

#footer .contacts-footer-content input.form-control,
#footer .contacts-footer-content select.form-control {
  height: 19px;
  display: inline-block;
  font-size: 11px;
}

#footer .contacts-footer-content {
  padding: 19px;
  background-color: #333;
}

#footer .contacts-footer-content label {
  display: block !important;
  color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 2px 2px 3px 0;
  overflow: hidden;
  background-color: #333;
  z-index: 1;
}

#footer .block-subscribe .block-title {
  display: none;
}

#footer .block-subscribe .block-content label {
  display: none;
}

#footer .contacts-footer-content .input-box {
  margin-bottom: 9px;
  position: relative;
}

#footer form .buttons-set {
  padding-top: 10px;
  text-align: right;
  border: none;
}

#footer form .buttons-set .btn {
  padding-top: 7px;
  padding-bottom: 7px;
}

#footer .call-block {
  float: right;
}

#footer .call-block .media-left {
  padding-right: 15px;
}

#footer .call-block .media-left,
#footer .call-block .media-body {
  vertical-align: middle;
  width: auto;
}

#footer .call-block h5 {
  margin: 0;
}

#footer .call-block p {
  margin: 0;
  line-height: 1;
}

body #footer h1,
body #footer h2,
body #footer h3,
body #footer h4,
body #footer h5,
body #footer h6,
body #footer .h1,
body #footer .h2,
body #footer .h3,
body #footer .h4,
body #footer .h5,
body #footer .h6 {
  color: $color-white;
}

body.wide-footer #footer .footer-top,
body.boxed-footer #footer .footer-top .container {
  background-color: $color-primary;
}

body.wide-footer #footer .footer-middle,
body.boxed-footer #footer .footer-middle .container {
  background-color: $color-primary;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

body.wide-footer #footer .footer-bottom,
body.boxed-footer #footer .footer-bottom .container {
  background-color: $color-primary-dark;
}

#footer p {
  margin-bottom: 7px;
  color: #aaa;
}

#footer .store-info p {
  line-height: 1.8em;
}

#footer .logo-wrapper p {
  line-height: 25px;
}

#footer ul li a {
  padding: 3px 0;
}

#footer .footer-block-title {
  margin-bottom: 24px;
  color: $color-white;
  line-height: 1;
}

.footer-custom div[id*=___plusone] {
  width: 60px !important;
}

.footer-custom .fb_iframe_widget {
  display: inline-block;
  vertical-align: top;
}

#footer a {
  color: $color-white;
}

#footer .links a {
  padding: 5px 0;
  display: inline-block;
}

#footer .links li:first-child a {
  padding-top: 0;
}

#footer .footer-links .container {
  border-top: 1px solid rgba(255, 255, 255, 0.13);
}

#footer ul.links li a:before {
  display: inline-block;
  content: '';
  background-repeat: no-repeat;
  height: 2px;
  width: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  vertical-align: middle;
  margin-right: 8px;
  position: relative;
  top: -2px;
}

#footer .footer-links-wrapper {
  display: block;
  text-align: center;
}

#footer .horizontal-links {
  padding: 15px 0;
  margin: 0;
}

#footer .horizontal-links li {
  display: inline-block;
  padding: 0;
  border-right: solid 1px rgba(255, 255, 255, .13);
  margin-left: -3px;
}

#footer .horizontal-links li a {
  padding: 7px 12px;
  color: $color-white;
}

#footer .horizontal-links li a:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

#footer .horizontal-links li:last-of-type {
  border: none;
}

#footer ul.social-links li + li {
  margin-left: 15px;
}

#footer ul.social-links li a {
  font-size: 28px;
  color: $color-white;
}

#footer .footer-banner {
  padding: 5px 0;
}

#footer .subscribe-block {
  text-align: center;
}

#footer .block-title,
#footer .block-subscribe {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
}

#footer .block-title {
  margin: 0;
  padding: 0 10px 0 0;
  border: none;
  color: $color-white;
  font-size: 16px;
}

#footer .block-title strong {
  font-weight: bold;
}

body #footer .block-subscribe .block-title {
  display: none;
}

#footer .subscribe-block .input-wrapper {
  width: 500px;
}

#footer .footer-bottom .container {
  position: relative;
  padding: 20px 10px;
}

#footer .socials-wrapper {
  position: absolute;
  left: 20%;
  right: 20%;
  text-align: center;
  line-height: 1;
  margin: 10px 0;
}

#footer hr.solid {
  border-color: rgba(255, 255, 255, 0.1);
}

#footer .payments-wrapper {
  margin: 3px 0;
}

#footer .shipping-wrapper {
  display: inline-block;
  padding: 5px 0;
}

#footer .store-switcher,
#footer .form-language,
#footer .form-currency {
  margin-left: 30px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

#footer .store-switcher label,
#footer .form-language .label,
#footer .form-currency .label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 20px;
  color: $color-white;
  font-weight: 400;
  margin-right: 10px;
}

#footer .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto;
}

#footer .bootstrap-select .selectpicker {
  height: auto;
  border: none;
  font-weight: normal;
  font-size: 11px;
  color: $color-white;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 22px 0 10px;
  border-radius: 40px;
  line-height: 20px;
  border: none;
}

#footer .bootstrap-select .selectpicker .caret {
  border-top-color: $color-white;
}

#footer .bootstrap-select .dropdown-menu {
  background: #eee;
  left: auto;
  right: 0;
  padding: 0;
  height: auto;
  border: none;
  border-radius: 0;
}

#footer .bootstrap-select .dropdown-menu li {
  padding: 0;
}

#footer .bootstrap-select .dropdown-menu li a {
  display: block;
  padding-right: 10px;
  padding-left: 10px;
  color: #222;
}

#footer .bootstrap-select .dropdown-menu li a:hover {
  background: #aaa;
}

#footer .bootstrap-select .dropdown-menu li a:before {
  content: none;
}

#footer .bottom-block {
  text-align: center;
}

#footer .copyright {
  margin: 3px 0;
  color: $color-white;
  font-size: 11px;
}

#footer address {
  font-size: 14px;
  margin: 0;
}

#footer address a {
  color: $color-white;
}

#footer .footer-content-text-block i.text-block-icon.delivery {
  background-position: -190px 0;
}

#footer .footer-content-text-block i.text-block-icon.market-trends {
  background-position: -250px 0;
}

#footer .offer-block {
  padding-top: 7px;
}

#footer .offer-block h6 {
  color: #666;
  margin: 0;
}

#footer .offer-block .media-left {
  padding-right: 19px;
}

#footer .offer-block .item + .item {
  margin-top: 5px;
}

/* ======================================================================================= */
.footer-top .schedule li:after,
.block-poll li:after,
.sorter:after,
.qty-holder:after,
hr[class*='indent-']:after {
  display: block;
  content: ".";
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  overflow: hidden;
}