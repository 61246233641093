/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "src/bootstrap/variables";
@import "src/bootstrap/mixins";

// Reset and dependencies
@import "src/bootstrap/normalize";
@import "src/bootstrap/print";
@import "src/bootstrap/glyphicons";

// Core CSS
@import "src/bootstrap/scaffolding";
@import "src/bootstrap/type";
@import "src/bootstrap/code";
@import "src/bootstrap/grid";
@import "src/bootstrap/tables";
@import "src/bootstrap/forms";
@import "src/bootstrap/buttons";

// Components
@import "src/bootstrap/component-animations";
@import "src/bootstrap/dropdowns";
@import "src/bootstrap/button-groups";
@import "src/bootstrap/input-groups";
@import "src/bootstrap/navs";
@import "src/bootstrap/navbar";
@import "src/bootstrap/breadcrumbs";
@import "src/bootstrap/pagination";
@import "src/bootstrap/pager";
@import "src/bootstrap/labels";
@import "src/bootstrap/badges";
@import "src/bootstrap/jumbotron";
@import "src/bootstrap/thumbnails";
@import "src/bootstrap/alerts";
@import "src/bootstrap/progress-bars";
@import "src/bootstrap/media";
@import "src/bootstrap/list-group";
@import "src/bootstrap/panels";
@import "src/bootstrap/responsive-embed";
@import "src/bootstrap/wells";
@import "src/bootstrap/close";

// Components w/ JavaScript
@import "src/bootstrap/modals";
@import "src/bootstrap/tooltip";
@import "src/bootstrap/popovers";
@import "src/bootstrap/carousel";

// Utility classes
@import "src/bootstrap/utilities";
@import "src/bootstrap/responsive-utilities";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Theme and Custom Styles

@import "src/variables";
@import "src/styles";
@import "src/default";
@import "src/typo";
@import "src/header";
@import "src/navigation";
@import "src/category";
@import "src/footer";
@import "src/checkout";

@import "src/spacer";
@import "src/ugiftcert";
@import "src/custom";
@import "src/home";