#header {
  position: relative;
  color: rgba(0, 0, 0, .6);
}

#header .top-block {
  position: relative;
}

.wide-header #header .top-block,
.boxed-header #header .top-block .container {
  font-size: 12px;
  color: #888;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.header .header-banners {
  display: inline-block;
  margin: 0;
  padding: 16px 0;
}

.header .header-banners .item {
  display: inline-block;
  float: left;
}

.header .header-banners .item + .item {
  margin-left: 45px;
}

#header h2.logo {
  display: inline-block;
  font-size: 1em;
  margin: 0;
}

.wide-header #header .bottom-block,
.boxed-header #header .bottom-block .container {
  background-color: #fff;
}

#header .bottom-block .container {
  position: relative;
}

.boxed-header #header ~ .container {
  padding-left: 0;
  padding-right: 0;
}

.header .middle-block .container {
  padding-top: 0;
  padding-bottom: 0;
}

.header .menu-wrapper {
  position: static;
  float: none;
}

.header .middle-block {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.header .middle-block .container {
  position: relative;
}

.header .middle-block .left-block {
  padding-top: 12px;
  padding-bottom: 12px;
}

.header .middle-block .right-block {
  padding-top: 12px;
  padding-bottom: 0;
  margin-bottom: -2px;
}

.header .bottom-block .main-menu-wrapper {
  display: block;
  background-color: $color-primary;
  border-radius: 25px 25px 0 0;
  padding: 0 5px;
  position: relative;
}

header.header .form-currency {
  margin: 0;
}

header.header .form-currency .currency-icon {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background: #eee;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  color: #222;
}

.header .welcome-msg {
  display: block;
  vertical-align: middle;
  margin: 0;
  text-align: center;
}

.header .header-phone {
  color: #666;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 13px;
  padding: 3px 0;
}

.header .header-phone i {
  font-size: 20px;
  line-height: 1;
  color: $color-secondary;
  vertical-align: middle;
  margin-right: 14px;
}

.header .header-phone span {
  vertical-align: middle;
}

.header .header-custom-links {
  display: inline-block;
}

.header .header-custom-links ul {
  margin: 0 20px 0 0;
  padding: 0;
  font-size: 0;
}

.header .header-custom-links li {
  display: inline-block;
}

.header .header-custom-links li:last-child {
  border: none;
}

.header .header-custom-links li a {
  color: #666;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
}

.header .header-custom-links li + li {
  padding-left: 15px;
}

.header .header-custom-links li + li a:before {
  content: '';
  width: 1px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.12);
  margin-right: 15px;
  position: relative;
  top: -1px;
}

.header .header-custom-links li a:hover {
  color: $color-primary;
}

.header .links {
  display: inline-block;
}

.header .logo-wrapper {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.header .left-block {
  float: left;
}

.header .right-block {
  float: right;
  text-align: right;
  line-height: 1;
}

.header .form-language,
.header .form-currency {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.header .form-language .label,
.header .form-currency .label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 20px;
  color: #666;
  font-weight: 400;
  margin-right: 10px;
}

.header .form-language + .form-currency {
  padding-left: 30px;
}

.header .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto;
  padding: 5px 0;
}

.header .bootstrap-select > .selectpicker {
  font-family: 'Noto Sans';
  height: auto;
  border: none;
  font-weight: normal;
  font-size: 11px;
  color: #666;
  text-transform: uppercase;
  background-color: #eee;
  padding: 0 22px 0 10px;
  border-radius: 40px;
  line-height: 20px;
  border: none;
}

.header .bootstrap-select > .selectpicker:hover,
.header .btn-group.open .dropdown-toggle {
  color: #222;
}

.header .btn-group.open .dropdown-toggle {
  outline: none;
  box-shadow: none;
}

.header .bootstrap-select.btn-group .dropdown-menu {
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, .15);
  border: none;
  right: 0;
  left: 0;
  min-width: 120px;
  width: 100%;
  padding: 10px 0;
}

.header .bootstrap-select.btn-group .dropdown-menu > li > a {
  color: #616161;
  font-size: 12px;
  padding: 10px 16px;
}

.header .bootstrap-select.btn-group .dropdown-menu > li > a:hover,
.header .bootstrap-select.btn-group .dropdown-menu > li > a:focus {
  background-color: #eee;
  color: #616161;
}

/**** Switcher ****/
.language-currency-wrapper {
  position: relative;
  display: inline-block;
  font-size: 11px;
  line-height: 1.5em;
  vertical-align: middle;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #666;
}

.language-currency-block {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 8px 14px;
  cursor: pointer;
  position: relative;
  transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
}

.language-currency-block:hover,
.language-currency-block.open {
  background-color: rgba(0, 0, 0, 0.1);
}

.language-currency-block i {
  font-size: 8px;
  margin-left: 0;
  font-size: 14px;
  vertical-align: middle;
  color: #555;
  line-height: 1;
  position: relative;
}

.footer-middle .language-currency-block,
.footer-bottom .language-currency-block,
.footer-middle .form-language.list a,
.footer-middle .form-currency.list a,
.footer-bottom .form-language.list a,
.footer-bottom .form-currency.list a {
  color: #fff;
}

.footer-middle .form-language.list a:hover,
.footer-middle .form-currency.list a:hover,
.footer-bottom .form-language.list a:hover,
.footer-bottom .form-currency.list a:hover {
  color: #222;
}

.language-currency-block .currency,
.language-currency-block .language {
  padding: 3px 0;
  line-height: 1;
  vertical-align: middle;
}

.language-currency-block .language img {
  top: -1px;
  position: relative;
}

.with-border .language-currency-block .language + .currency,
.with-border .language-currency-block .currency + .language {
  padding-left: 9px;
  margin-left: 6px;
  border-left: solid 1px rgba(0, 0, 0, .16);
  text-align: center;
}

.language-currency-block .currency .icon {
  display: inline-block;
}

.language-currency-block .currency i {
  margin-left: 3px;
  margin-top: 3px;
}

.language-currency-dropdown {
  width: 230px;
  padding: 0 20px;
  background: #fff;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  display: none;
  clear: both;
  z-index: 9999;
  left: 0;
  color: #333;
  text-align: left;
}

.language-currency-dropdown .label {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  display: block;
  padding: 0;
  text-align: left;
  border-radius: 0;
  line-height: 1.5;
}

.language-currency-dropdown .form-language > .btn-group {
  margin-top: .5em;
  margin-bottom: 1em;
}

.language-currency-dropdown .form-currency.list a {
  display: block;
  font-size: 14px;
  color: rgba(0, 0, 0, .86);
  border-bottom: solid 1px rgba(0, 0, 0, .16);
  margin: 0 -30px !important;
  padding: 1em 30px;
}

.language-currency-dropdown .form-language.list a {
  float: none;
  display: block;
  text-align: left;
  padding: 13px 0;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
}

.language-currency-dropdown .form-language.list a + a {
  border-top: 1px dotted rgba(0, 0, 0, 0.12);
}

.language-currency-dropdown .form-language.list a:hover,
.language-currency-dropdown .form-currency.list a:hover {
  color: inherit;
  text-decoration: underline;
}

.language-currency-dropdown > div > a:last-of-type {
  border-bottom: none;
}

.language-currency-dropdown > div > a img {
  margin: -1px 9px 0 0;
}

.language-currency-dropdown .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.language-currency-dropdown .bootstrap-select.btn-group .dropdown-menu li a {
  color: inherit;
}

.form-language.list a,
.form-currency.list a {
  color: #666;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.form-language.list a:hover,
.form-currency.list a:hover {
  color: #222;
}

.form-language.list a:last-child,
.form-currency.list a:last-child {
  margin-right: 0;
}

/* Header links */
.header .links {
  display: inline-block;
  padding-left: 26px;
  margin: 0;
  vertical-align: middle;
}

.header .default-links.links {
  margin: 3px 0 3px 10px;
}

.header .default-links.links li {
  margin-left: 10px;
  float: left;
}

.header .default-links.links li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.header .default-links.links li + li {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}

.header .default-links.links li a {
  color: inherit;
}

.header .default-links.links li a.top-link-logout {
  display: inline-block;
  padding-top: 1px;
}

.header .default-links.links li a:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 6px;
  position: relative;
}

.header .default-links.links li a.top-link-account:before {
  content: "\f007";
}

.header .default-links.links li a.top-link-wishlist:before {
  content: "\f004";
}

.header .default-links.links li a.top-link-checkout:before {
  content: "\f155";
}

.header .default-links.links li a.top-link-login:before {
  content: "\f084";
}

.header .default-links.links li a:hover {
  color: $color-primary;
}

.header .customer-block {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  top: 2px;
}

.header .customer-name-wrapper .customer-name {
  display: block;
  cursor: pointer;
}

.page-sitemap .customer-block {
  display: none !important;
}

.header .links:not(.default-links) li:not(.last) {
  display: none;
}

.header .links:not(.default-links) li a {
  font-size: 0;
}

.header .links:not(.default-links) li a.top-link-login {
  font-size: 15px;
  color: #222;
  font-weight: 400;
}

.header .create-account a {
  font-size: 15px;
  color: #222;
  font-weight: bold;
}

.header .links:not(.default-links) li a.top-link-login:before {
  content: "\f090";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  margin-right: 9px;
  position: relative;
  top: 1px;
}

.header .create-account {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
}

.header .create-account:before {
  content: attr(data-label);
  display: inline-block;
  margin: -3px 9px -3px 8px;
  color: #aaa;
  font-weight: 400;
}

.header .links:not(.default-links) li a.top-link-login:hover {
  color: $color-primary;
}

.header .customer-name .user-icon {
  font-size: 14px;
  color: #222;
}

.header .customer-name .user-icon span {
  display: inline-block;
  position: relative;
  top: -1px;
}

.header .customer-name .user-icon i.fa {
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.header .customer-name i.caret {
  color: #aaa;
  margin-left: 5px;
  font-size: 1em;
  top: -2px;
  position: relative;
  margin-right: 0;
}

header.header .customer-name-wrapper + .links {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  right: 0;
  z-index: 99999;
  margin-left: 0 !important;
  padding: .75em 9px;
  min-width: 153px;
  text-align: left;
}

header.header .customer-name-wrapper + .links li {
  float: none;
  display: block !important;
  margin-left: 10px;
}

header.header .customer-name-wrapper + .links li a {
  display: inline-block !important;
  font-size: 1em;
  float: none;
  text-align: left;
  position: relative;
  margin: 0;
  padding: .5em 0 .5em 10px;
  color: #666;
  &:hover{
    text-decoration: none;
  }
}

header.header .customer-name-wrapper + .links li a i {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  font-size: .4em;
  color: $color-primary;
}

header.header .customer-name-wrapper + .links li a .hover-divider {
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 10px;
  background-color: $color-primary;
  content: '';
  position: absolute;
  -webkit-transition: width 500ms ease;
  -moz-transition: width 500ms ease;
  transition: width 500ms ease;
  -o-transition: width 500ms ease;
}

header.header .customer-name-wrapper + .links li a:hover .hover-divider {
  width: 90%;
}

header.header .customer-name-wrapper + .links li a:hover {
  color: $color-primary;
  text-decoration: none;
}

/********** < Navigation */
div.topmenu {
  padding: 0;
}

ul.topmenu {
  border-width: 0;
  padding: 0;
  margin: 0;
}

ul.topmenu li.level0:first-child {
  padding-left: 0;
}

ul.topmenu li.level0:not(:first-child) {
  margin-left: 0;
}

ul.topmenu li.level0 a.level-top {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.429;
  color: #fff;
  padding: 14px 18px;
  z-index: 999;
  background: none;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}

#header ul.topmenu li.level0.first a.level-top {
  border-radius: 20px 0 0 0;
}

ul.topmenu li.level0:not(.first) a.level-top:not(.vertical-parent ):before {
  content: '';
  height: 14px;
  width: 1px;
  background-color: rgba(255, 255, 255, .1);
  position: absolute;
  top: 50%;
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

ul.topmenu li.level0 a.level-top:hover,
ul.topmenu li.level0.over a.level-top {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

ul.topmenu.navbar-nav li.level0.active > a.level-top {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

ul.topmenu li.level0.parent a.level-top span:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, .3);
  vertical-align: middle;
  margin-left: 4px;
  position: relative;
}

ul.topmenu.navbar-nav li.level0.active > a.level-top span:after {
  border-top-color: rgba(255, 255, 255, .6);
}

ul.topmenu li.level0 a.level-top span {
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

ul.topmenu li.level0.vertical-parent + li.level0 a.level-top:before {
  content: none;
}

ul.topmenu li.level0 a.level-top .custom-icon {
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

#header ul.topmenu li.level0.first a.level-top .custom-icon.ion-ios-home-outline {
  margin-left: -30px;
}

ul.topmenu li.level0 a.level-top .custom-icon:before {
  line-height: .5;
}

ul.topmenu li.level0 a.level-top .custom-icon + span {
  display: none;
  margin-left: 3px;
}

ul.topmenu li {
  text-align: left;
  position: relative;
  float: left;
}

ul.topmenu li:not(.first) {
  margin-left: 0;
}

ul.topmenu li a {
  display: block;
  text-decoration: none;
}

ul.topmenu li a:hover {
  text-decoration: none;
  color: #000;
}

ul.topmenu li a span {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
}

ul.topmenu li a:not(.lightbox-button) i:not(.custom-icon) {
  font-size: .5em;
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: -1px;
}

ul.topmenu li:not(.item) > a {
  float: left;
  padding: 5px 10px;
  font-weight: bold;
  color: #888;
}

ul.topmenu li ul.shown-sub,
ul.topmenu li div.shown-sub {
  display: block;
}

ul.topmenu li .shown-sub ul.shown-sub,
ul.topmenu li .shown-sub li div.shown-sub {
  left: 100px;
  top: 50px;
}

ul.topmenu:not(.nav-wide) ul,
ul.topmenu:not(.nav-wide) div {
  position: absolute;
  width: 15em;
  top: 100%;
  left: 0;
  display: none;
  background: #fff;
  padding: .833em 20px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

ul.topmenu:not(.nav-wide) div ul {
  position: static;
  width: auto;
  border: none;
}

ul.topmenu ul ul {
  top: 7px;
}

ul.topmenu ul li {
  float: none;
}

ul.topmenu ul li.last {
  border-bottom: 0;
}

ul.topmenu ul li:not(.item) a {
  float: none;
  position: relative;
  display: inline-block;
  padding: .5em 0 .5em 10px;
  font-weight: normal;
  color: #666;
}

ul.topmenu ul li a:hover,
ul.topmenu ul li.active > a:hover,
ul.topmenu ul li.over > a:hover,
ul.topmenu ul li.active > a,
ul.topmenu ul li.over > a,
ul.topmenu ul li.active > a:hover i.fa,
ul.topmenu ul li.over > a:hover i.fa,
ul.topmenu ul li.active > a i.fa,
ul.topmenu ul li.over > a i.fa {
  color: $color-primary;
}

ul.topmenu ul li a:not(.lightbox-button) i.fa {
  color: #5c5755;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  font-size: .4em;
  line-height: 1;
  padding: 0;
  right: auto;
}

ul.topmenu ul li a .hover-divider {
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 10px;
  background-color: $color-primary;
  content: '';
  position: absolute;
  -webkit-transition: width 500ms ease;
  -moz-transition: width 500ms ease;
  transition: width 500ms ease;
  -o-transition: width 500ms ease;
}

ul.topmenu ul li a:hover .hover-divider,
ul.topmenu ul li.active > a .hover-divider {
  width: 90%;
}

/********** Wide Navigation > */
body .navbar-default {
  background: none;
  border: none;
  background-image: none;
  box-shadow: none;
  position: static;
  min-height: 1px;
  margin: 0;
  display: inline-block;
  float: left;
}

.nav-wide {
  position: static;
  text-align: left;
}

.nav-wide ul {
  display: block;
}

.nav-wide li.level-top {
  display: inline-block;
  vertical-align: top;
  position: static;
}

.nav-wide a.level-top span {
  display: inline;
}

.nav-wide .menu-wrapper {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  text-align: left;
  background-color: #fff;
  border-top: solid 2px $color-primary;
  z-index: 99999;
  padding: 40px 40px 20px;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-top: -1px;
  overflow: hidden;
  background-size: cover;
}

.nav-wide .menu-wrapper .meigee-widget .products-grid {
  margin-left: 0;
  margin-right: 0;
}

.nav-wide .menu-wrapper .meigee-widget .products-grid .product-name a {
  font-size: 1em;
}

.nav-wide .menu-wrapper > div.first {
  padding-left: 18px;
}

.nav-wide .menu-wrapper .sub-content {
  padding-left: 18px;
  padding-right: 18px;
}

.nav-wide .menu-wrapper .sub-content .menu-banners {
  margin: -80px -58px 0;
  position: relative;
  bottom: -40px;
}

.nav-wide .menu-wrapper .sub-content .menu-banners .banner {
  float: left;
  width: 25%;
}

.nav-wide .menu-wrapper .sub-content .menu-banners .banner img {
  width: 100%;
}

.nav-wide .menu-wrapper ul.level0 {
  margin-left: -8px;
  margin-right: -8px;
}

.nav-wide .menu-wrapper ul.level0 li.level1 {
  display: inline-block;
  float: none;
  padding: 0 8px 10px 8px;
  vertical-align: top;
}

.nav-wide .menu-wrapper ul.level0 li.level1 > a {
  display: block;
  position: relative;
  padding: 0;
}

.nav-wide .menu-wrapper ul.level0 li.level1 > ul {
  margin: 0 10px 1em 0;
  padding-left: 0;
}

.nav-wide .menu-wrapper ul.level0 li.level1 > ul + ul {
  margin-top: .75em;
}

.nav-wide .menu-wrapper ul.level0 li.level1 span.subtitle {
  color: rgba(0, 0, 0, .86);
  font-size: 1.167em;
  line-height: 1.5em;
  display: block;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 1em;
}

.nav-wide .menu-wrapper ul.level0 li.level1 span.subtitle:before {
  content: none;
}

.nav-wide .menu-wrapper ul.level0 li.level1 span.subtitle:hover {
  color: $color-primary;
}

.nav-wide .menu-wrapper ul.level1 a {
  position: relative;
  text-transform: none;
  font-size: 1em;
  color: #666;
  line-height: 1.5em;
  display: block;
  font-size: 13px;
  color: rgba(0, 0, 0, .6);
  padding-left: 0;
}

.nav-wide .menu-wrapper ul.level0 li.level1 a:hover {
  color: $color-primary;
}

.nav-wide .menu-wrapper ul.level0 li.level1 a span:before {
  content: "\e606";
  display: inline-block;
  font-family: "simple-line-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 5px;
  color: $color-primary;
}

.nav-wide .menu-wrapper .top-content {
  margin-bottom: 3em;
  padding: 0;
  overflow: hidden;
  font-size: 1em;
}

.nav-wide .menu-wrapper .bottom-content {
  overflow: hidden;
  margin: 1.5em 18px 0;
  background-color: #dcdcdc;
  font-size: 1em;
  color: #888;
  line-height: 1.5em;
  padding: 1em 20px;
}

.nav-wide .menu-wrapper .right-content {
  display: inline-block;
  float: none;
  font-size: 1em;
}

.nav-wide .inline-links {
  margin-bottom: 9px;
}

.nav-wide .inline-links li {
  display: inline;
  padding: 0 10px 0 0;
}

.nav-wide .inline-links li a {
  display: inline-block;
  padding: 5px 10px;
}

.nav-wide .inline-links li a:hover {
  background-color: #f5f5f5;
}

.nav-wide p {
  margin-bottom: 9px;
}

.nav-wide .awesome-content-top,
.nav-wide .awesome-content-left {
  background: #f5f5f5;
  color: #444;
  text-align: center;
  font-size: 1.24em;
  line-height: 1.01em;
  padding: 18px;
  margin: 0 0 18px 0;
  overflow: hidden;
}

.nav-wide .awesome-content-top i,
.nav-wide .awesome-content-left i {
  font-size: 1.18em;
  display: block;
  margin-bottom: 5px;
}

.nav-wide .awesome-content-left {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 19px;
}

.nav-wide .awesome-content-left i {
  float: left;
  margin: 0 10px 5px 0;
}

.nav-wide .products-grid li.item {
  margin-bottom: 0;
}

.nav-wide .menu-banner {
  float: right;
  margin: 40px;
}

/* Category Labels */
.category-label {
  display: none;
  transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
  font-style: normal;
  padding: 1px 5px 2px;
  z-index: 3;
  font-family: Noto Sans;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 1em;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  top: 0;
  left: 9px;
  border-radius: 0 0 5px 5px;
  position: absolute;
}

.category-label.label_one {
  background-color: #ffed2a;
  color: #222;
}

.category-label.label_two {
  background-color: #8ac2c3;
}

.category-label.label_three {
  background-color: $color-secondary;
}

.menu-wrapper .category-label {
  top: 3px;
  left: 20px;
  bottom: auto;
}

.menu-wrapper .subtitle + .category-label {
  left: 0;
  top: -7px;
  bottom: auto;
}

/* ul.topmenu a.over > .category-label.label_one,
ul.topmenu li.active a > .category-label.label_one,
ul.topmenu a.over > .category-label.label_two,
ul.topmenu li.active a > .category-label.label_two,
ul.topmenu a.over > .category-label.label_three,
ul.topmenu li.active a > .category-label.label_three {background-color: #222;} */

/* For Top Menu */
@media only screen and (min-width: 978px) {
  .nav-wide li.over .menu-wrapper {
    display: block !important;
  }
  .category-label {
    display: inline-block;
    vertical-align: middle;
  }
  /* Default Menu in Wide Mode */
  .nav-wide .menu-wrapper.default-menu {
    position: absolute;
    width: 15em;
    top: 100%;
    left: 0;
    display: none;
    padding: 0;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    overflow: visible;
  }
  .nav-wide .menu-wrapper.default-menu ul {
    background-color: inherit;
    background: #fff;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 {
    clear: both;
    display: block;
    float: none;
    margin: 0;
    position: relative;
    padding: 0;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li:last-of-type {
    border-bottom: none;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.over > a,
  .nav-wide .menu-wrapper.default-menu ul.level0 li > a:hover {
    background-color: #f5f5f5;
    color: $color-primary;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 a {
    padding: 0 9px;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li a span.subtitle {
    color: inherit !important;
  }
  .nav-wide .menu-wrapper.default-menu li.level1.parent > a:after,
  .nav-wide .menu-wrapper.default-menu li.level1.parent li.parent > a:after {
    right: 20px;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 a,
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 li a {
    float: none;
    position: relative;
    display: block;
    padding: 13px 29px 13px 30px;
    font-weight: normal;
    margin: 0;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 li.level2 a {
    line-height: 1.5em;
    font-size: 1.167em;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 a span:before {
    content: none;
    width: 6px;
    height: 6px;
    border: solid 2px #ddd;
    background-color: #fff;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 a:hover span.menu-wrapper:not(.default-menu) {
    background: none !important;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 li.level1 a span:not(.hover-divider) {
    float: none;
    position: relative;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    padding: 0;
    border: none;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 ul {
    margin: 0;
    padding: 0;
    border: none;
    display: none;
    width: 15em;
    position: absolute;
    top: -10px;
    left: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 ul li { /*  padding: 0 19px; */
    position: relative;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 ul li a {
    padding-left: 0;
  }
  .nav-wide .menu-wrapper.default-menu ul.level0 ul a span:before {
    display: none;
  }
}

/********** Navigation > */

.header .menu-block .container {
  padding-top: 0;
  padding-bottom: 0;
}

.header .logo strong,
.header .small-logo strong {
  position: absolute;
  top: -999em;
  left: -999em;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -999em;
  overflow: hidden;
  display: none;
}

.header .search-mini-form {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 0;
  float: right;
}

.header .search-mini-form .form-search {
  position: relative;
  overflow: hidden;

  .search-button {
    display: none;
    cursor: pointer;
    font-size: 24px;
    line-height: 54px;
    text-align: center;
    color: #222;
    width: 54px;
    height: 54px;
    transition: background-color 300ms ease, color 300ms ease;
    -moz-transition: background-color 300ms ease, color 300ms ease;
    -webkit-transition: background-color 300ms ease, color 300ms ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    span {
      display: none;
    }

    i {
      font-size: inherit;
      color: inherit;
    }

  }

}

.header .search-mini-form .form-search .indent {
  width: 240px;
  position: static;
  border: none;
}

.header .search-mini-form .form-search .input-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    transition: border 300ms ease;
    -moz-transition: border 300ms ease;
    -webkit-transition: border 300ms ease;
    -o-transition: border 300ms ease;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: #003073;
}

.header .search-mini-form .form-search label {
  display: none;
}

.header .search-mini-form .form-search input {
  display: inline-block;
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  font-size: 15px;
  vertical-align: middle;
  padding-left: 7px;
  height: 40px;
}

.header .search-mini-form .form-search .btn {
  display: inline-block;
  overflow: hidden;
  margin-right: 7px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-family: 'Rubik';
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 40px;
  background-color: #f8f8f8;
  background-image: none;
  box-shadow: none;
  vertical-align: middle;
  color: #aaa;
  position: relative;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.header .search-mini-form .form-search .btn:hover,
.header .search-mini-form .form-search .btn:focus,
.header .search-mini-form .form-search .btn:active {
  background-color: $color-primary;
  color: #fff;
}

.header .search-mini-form .form-search .search-icon {
  padding-left: 16px;
  position: relative;
  border-radius: 40px 0 0 40px;
}

.header .search-mini-form .form-search .search-icon i {
  line-height: 1;
  font-size: 14px;
  vertical-align: middle;
  color: #fff;
}

.header .search-mini-form .form-search .btn span {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}

.header .search-mini-form .form-search .btn > span {
  line-height: inherit;
}

.header .search-mini-form .form-search .btn span span {
  font-size: 0;
  opacity: 0;
  display: inline-block;
  color: #222;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 1;
  margin-top: -2px;
  transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
}

.header .search-mini-form .form-search .btn i {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: inherit;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  margin: 0 0 0 3px;
  position: relative;
  top: -1px;
}

.header .search-mini-form .form-search .btn i + span {
  display: none;
}

.header .search-mini-form.focused .indent {
  border-color: #4c66d9;
}

.header .search-mini-form.focused .form-search .btn {
  width: 105px !important;
  background-color: transparent;
  -webkit-box-shadow: -2px 2px 10px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 2px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 2px 10px -2px rgba(0, 0, 0, 0.2);
}

.header .search-mini-form.focused .form-search .search-icon {
  background-color: #f5f5f5;
}

.header .search-mini-form.focused .form-search .btn i {
  margin-left: 5px;
}

.header .search-mini-form.focused .form-search .btn > span {
  margin-left: 7px;
}

.header .search-mini-form.focused .form-search .btn span span {
  font-size: 13px;
  opacity: 1;
}

.header .search-mini-form .form-search .search-autocomplete {
  z-index: 999;
}

.header .search-mini-form .form-search .search-autocomplete ul {
  border: 1px solid rgba(0, 0, 0, .1);
  background-color: #fff;
  color: #666;
  text-align: left;
}

.header .search-mini-form .form-search .search-autocomplete ul li {
  padding: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;
}

.header .search-mini-form .form-search .search-autocomplete ul li .amount {
  float: right;
  font-weight: bold;
}

.fb_iframe_widget > span,
.fb_iframe_widget iframe {
  max-width: 100% !important;
}

/* Menu button */
#header .menu-button {
  font-size: 12px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 5px;
}

#header .menu-button i {
  font-size: 20px;
  margin-right: 5px;
  line-height: 1;
  vertical-align: middle;
}

#header .menu-button span {
  display: inline-block;
  vertical-align: middle;
}

/**** Right Menu ****/

.header-wrapper .header-menu-wrapper {
  position: relative;
  overflow: hidden;
}

.header-wrapper .header-menu {
  position: absolute;
  background: #fff;
  right: -100%;
  z-index: 99999;
  height: 100%;
  transition: right 400ms ease;
  -moz-transition: right 400ms ease;
  -webkit-transition: right 400ms ease;
  -o-transition: right 400ms ease;
  min-width: 280px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, .1);
}

.header-wrapper .header-menu .btn-close {
  position: absolute;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 40px;
  left: -40px;
  top: 0;
  font-size: 1.16em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 1px;
}

.header-wrapper .header-menu .btn-close:hover {
  background-color: #222;
}

.header-wrapper .header-menu .right-menu {
  padding: 1.5em 20px;
}

.header-wrapper .header-menu .right-menu li {
  position: relative;
}

.header-wrapper .header-menu .right-menu li a {
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  color: #222;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.583em;
  display: block;
  padding: 8px 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}

.header-wrapper .header-menu .right-menu li:last-of-type a {
  border-bottom: none;
}

.header-wrapper .header-menu .right-menu .menu-item-button {
  position: absolute;
  right: 0;
  top: 14px;
  line-height: 1;
  z-index: 9;
  cursor: pointer;
}

.header-wrapper .header-menu .right-menu .menu-item-button i {
  font-size: 14px;
}

.header-wrapper .header-menu .right-menu .menu-item-button i.fa-minus-square-o {
  display: none;
}

.header-wrapper .header-menu .right-menu .menu-item-button.active i.fa-minus-square-o {
  display: block;
}

.header-wrapper .header-menu .right-menu .menu-item-button.active i.fa-plus-square-o {
  display: none;
}

.header-wrapper .header-menu .right-menu ul {
  margin-bottom: 1.5em;
}

.header-wrapper .header-menu .right-menu ul:last-child {
  margin-bottom: 0;
}

header.header .right-menu-button {
  font-size: 14px;
  color: #222;
  cursor: pointer;
  text-align: right;
  height: 60px;
  padding: 0 15px;
  cursor: pointer;
  float: right;
  transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  -webkit-transition: background-color 400ms ease;
  -o-transition: background-color 400ms ease;
}

header.header .right-menu-button:hover {
  color: $color-primary;
}

.header-wrapper .header-menu .right-menu .submenu li a {
  font-size: 12px;
  color: #666;
  font-family: Noto Sans;
  text-transform: none;
  padding: 10px 0;
}

.header-wrapper .header-menu .right-menu .submenu li a:before {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  content: '';
  height: 4px;
  width: 4px;
  margin: -2px 9px 0;
  border-radius: 50%;
  background-color: $color-primary;
}

header.header .right-menu-button i {
  line-height: 60px;
}

/* Header Cart ================================================================================ */
header.header .top-cart {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  margin-bottom: 10px;
}

header.header .top-cart .block-title {
  border: none;
  margin: 0;
  z-index: 1034;
  padding: 0;
  font-size: 1em;
}

header.header .top-cart .block-title:after {
  content: none;
}

header.header .top-cart .block-title a:hover {
  color: inherit;
}

header.header .top-cart .block-title .title-cart {
  display: block;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: none;
}

header.header .top-cart .block-title .right-block {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0;
    border-radius: 40px;
    background-color: $color-orange-light;
    color: $color-grey-dark;
    border-width: 1px;
    border-style: solid;
    border-color: #de941e;
    margin-left: -45px;
    position: relative;
    z-index: 1;
}

header.header .top-cart .block-title .title-cart:hover {
  opacity: .9;
}

header.header .top-cart .block-title .cart-label {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  padding-left: 5px;
}

header.header .top-cart .block-title .cart-label i {
  margin-left: 3px;
}

header.header .top-cart .block-title .cart-label .price {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

header.header .top-cart .block-title .cart-label .bottom {
  display: block;
  line-height: 1;
}

header.header .top-cart .block-title .top-cart-icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

header.header .top-cart .block-title .top-cart-icon i {
  color: inherit;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
}

header.header .top-cart .block-title .subtotal {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 40px;
    font-size: 15px;
    padding: 12px 40px 12px 20px;
    background-color: $color-primary;
    border-width: 1px;
    border-style: solid;
    border-color: $color-blue-dark;
}

header.header .top-cart .block-title .subtotal .price {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

header.header .top-cart .block-title .cart-qty {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-size: 11px;
  color: #aaa;
  font-family: 'Noto Sans', sans-serif;
}

#header.header .top-cart .block-title .subtotal,
#header.header .top-cart .block-title .right-block {
  transition: padding 75ms ease-in;
  -moz-transition: padding 75ms ease-in;
  -webkit-transition: padding 75ms ease-in;
  -o-transition: padding 75ms ease-in;
  position: relative;
}

#header.header .top-cart .block-title.active .subtotal {
  padding: 12px 20px 12px 40px;
}

#header.header .top-cart .block-title.active .right-block {
  margin-left: -45px;
}

header.header .top-cart .block-title .cart-qty span {
  display: none;
}

header.header .top-cart .block-content {
  background: #fff;
  z-index: 9998;
  position: absolute;
  right: 0px;
  top: 100%;
  width: 404px;
  padding: 0;
  margin-top: -1px !important;
  color: #666;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 14px 20px 20px;
}

header.header .top-cart .block-content .inner-wrapper {
  position: relative;
}

header.header .top-cart .block-content .close-btn {
  font-size: 1em;
  display: none;
  position: absolute;
  top: 0;
  left: -50px;
  opacity: 1;
  z-index: 99;
  height: 50px;
  width: 50px;
  text-align: center;
  text-shadow: none;
  background-color: $color-primary;
  cursor: pointer;
  transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
}

header.header .top-cart .block-content .close-btn i {
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  display: block;
}

header.header .top-cart .block-content .close-btn i::before {
  line-height: inherit;
}

header.header .top-cart .block-content .close-btn:hover {
  background-color: #444444;
}

header.header .top-cart .block-content h4 {
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  padding: 0 0 9px;
}

header.header .top-cart .block-content.no-items + .block-content {
  text-align: center;
}

header.header .top-cart .block-content .cart-empty {
  padding: 1em 0;
  margin: 0;
  text-align: center;
  font-size: 1.16em;
}

header.header .top-cart .block-content .cart-empty i {
  display: block;
  color: #ccc;
  font-size: 56px;
  margin-bottom: 0.1em;
}

header.header .top-cart .block-content .cart-empty .small-label {
  display: block;
  font-size: 0.78em;
}

header.header .top-cart .block-content .cart-empty a {
  color: inherit;
  font-size: inherit;
}

header.header .top-cart .block-content .cart-empty a:hover {
  text-decoration: underline;
}

header.header .top-cart .block-content .media-left {
  padding-right: 15px;
}

header.header .top-cart .block-content .product-image {
  width: 90px;
  padding: 0;
  border: none;
  display: block;
  overflow: hidden;
}

header.header .top-cart .block-content .top-cart .cart-price-qt {
  display: block;
  margin-top: .25em;
}

header.header .top-cart .block-content .top-cart .cart-price-qt strong {
  font-size: 1em;
  color: #fff;
  display: inline-block;
  position: relative;
  font-weight: normal;
  padding: 1px 9px;
  border-radius: 10px;
  background-color: #ffa96a;
}

header.header .top-cart .block-content .mini-products-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: dotted 1px #ddd;
}

header.header .top-cart .block-content .mini-products-list li {
  padding: 0 0 20px;
  margin: 0;
  clear: both;
  position: relative;
}

header.header .top-cart .block-content .mini-products-list li:last-of-type {
  padding: 0;
}

header.header .top-cart .block-content .mini-products-list .product-details {
  float: right;
  width: 68%;
  padding-right: 7%;
  margin: 0;
  position: relative;
}

header.header .top-cart .block-content .mini-products-list .product-name {
  margin-bottom: 2px;
  padding-right: 50px;
  text-align: left;
}

header.header .top-cart .block-content .mini-products-list .product-name a {
  font-size: 14px;
  color: #222;
  font-weight: 600;
}

header.header .top-cart .block-content .mini-products-list .cart-price-qt {
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  color: #666;
  font-size: 12px;
  display: block;
  vertical-align: middle;
}

header.header .top-cart .block-content .mini-products-list .cart-price-qt:before {
  display: inline-block;
  content: '';
  background-color: #4cba1b;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 2px;
}

header.header .top-cart .block-content .mini-products-list .cart-price-qt strong {
  font-weight: inherit;
}

header.header .top-cart .block-content .mini-products-list .price {
  display: block;
  vertical-align: middle;
  font-size: 18px;
  margin-bottom: 8px;
}

header.header .top-cart .block-content .cart-actions {
  position: absolute;
  right: 0;
  top: 2px;
}

header.header .top-cart .block-content .cart-actions a {
  padding: 0;
  border: none;
  float: none !important;
  display: block;
}

header.header .top-cart .block-content .cart-actions a + a {
  margin-left: 0;
  margin-top: 5px;
}

header.header .top-cart .block-content .subtotal {
  display: inline-block;
  margin: 0;
  padding: 25px 0 5px;
}

header.header .top-cart .block-content .subtotal .subtotal-wrapper {
  display: block;
}

header.header .top-cart .block-content .subtotal .label,
header.header .top-cart .block-content .subtotal .price {
  display: inline-block;
  line-height: 1.25em;
  padding: 0;
  font-size: 24px;
}

header.header .top-cart .block-content .subtotal .price {
  letter-spacing: -1px;
}

header.header .top-cart .block-content .subtotal .label {
  color: #222;
  font-size: 11px;
  text-transform: uppercase;
}

header.header .top-cart .block-content .actions {
  position: relative;
  float: right;
  margin: 0;
  border: none;
  padding-top: 20px;
}

header.header .top-cart .block-content .actions .btn {
  padding-left: 37px;
  padding-right: 37px;
}

header.header .top-cart .block-content .actions .btn i {
  margin-left: 6px;
  margin-right: 0;
}

header.header .top-cart .block-content .actions .btn.btn-default span span {
  display: none;
}

header.header .top-cart .block-content .actions .btn.btn-default {
  margin: 0;
  padding: 0;
  background-color: #ddd;
}

header.header .top-cart .block-content .actions .btn.btn-default:hover,
header.header .top-cart .block-content .actions .btn.btn-default:active,
header.header .top-cart .block-content .actions .btn.btn-default:focus {
  background-color: $color-primary;
  color: #fff;
}

header.header .top-cart .block-content .actions .btn.btn-default i {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  height: 39px;
  width: 39px;
  line-height: 39px;
}

header.header .top-cart .block-content .product-box {
  font-size: 1em;
  line-height: 1.5em;
  text-align: left;
}

header.header .top-cart.top-cart.horizontal .fa-angle-down {
  margin-left: 3px;
}

header.header .top-cart .block-content .btn-default {
  background-color: transparent;
  border: none;
  color: #666;
  font-size: 10px;
  font-weight: 600;
  padding: 0;
}

/* Sticky Header */
body.boxed-header #sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: none;
}

body.boxed-header #sticky-header .container {
  background: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}

body.wide-header #sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  display: none;
}

#sticky-header .small-logo {
  margin: 4px 20px 7px 0;
  width: 170px;
}

#sticky-header .form-search input {
  width: 225px;
}

#sticky-header .navbar {
  margin: 0;
  border: none;
}

#sticky-header .quick-access {
  margin: 4px 0;
}

#sticky-header ul.topmenu li.level0 + li.level0:after,
#sticky-header ul.topmenu li.level0 + li.level0:before {
  content: none;
}

#sticky-header ul.topmenu li.level0:not(.first) a.level-top:not(.vertical-parent ):before {
  background-color: rgba(0, 0, 0, 0.1);
}

#sticky-header ul.topmenu li.level0.parent a.level-top span:after {
  border-top-color: rgba(0, 0, 0, 0.3);
}

#sticky-header ul.topmenu li a {
  color: #222;
  padding: 16px 18px;
}

#sticky-header .search-button {
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 10px 3px 0;
  color: #666;
  text-align: center;
  float: right;
}

#sticky-header .search-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#sticky-header .search-mini-form {
  margin: 0;
}

#sticky-header .search-mini-form .form-search {
  overflow: visible;
  border: none;
}

#sticky-header .form-search input {
  width: 200px;
}

#sticky-header .search-mini-form .form-search .input-wrapper {
  background-color: $color-primary-dark;
}

#sticky-header .form-search {
  position: relative;
}

#sticky-header .form-search .indent {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 1.5em 20px;
  width: 306px;
  z-index: 99;
}

#sticky-header .search-mini-form .form-search .search-button i {
  font-size: 18px;
  vertical-align: middle;
  position: relative;
  top: -3px;
}

#sticky-header .search_mini_form {
  display: none;
}

#sticky-header .top-cart {
  margin: 3px 0;
}

#sticky-header .top-cart .block-title .cart-label {
  display: none;
}

#sticky-header .top-cart .block-title .right-block {
  margin-left: -35px;
}