$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        '-1': ($spacer * -1 * .25),
        '-2': ($spacer * -1 * .5),
        '-3': ($spacer * -1),
        '-4': ($spacer * -1 * 1.5),
        '-5': ($spacer * -1 * 3)
) !default;

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size}  { #{$prop}:        $length !important; }
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length !important; }
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
      #{$prop}-left:  $length !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top:    $length !important;
      #{$prop}-bottom: $length !important;
    }
  }
}

// Some special margin utils
.m-auto  { margin:        auto !important; }
.mt-auto { margin-top:    auto !important; }
.mr-auto { margin-right:  auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ml-auto { margin-left:   auto !important; }
.mx-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}
.my-auto {
  margin-top:    auto !important;
  margin-bottom: auto !important;
}

@media(min-width:768px){
  .pl-5-sm {
    padding-left: 3rem!important;
  }
}
